
import React from "react";
import {Header, Footer} from "./components/layout";


const PageTemplate = ({content }) => {
  return (
    <div>
      <Header />
      <main>
        <div>{content}</div>
      </main>
      <Footer />
    </div>
  );
};

export default PageTemplate;