
import React from 'react';
import { Link } from "react-router-dom";
import github from "../theme/images/github.png"
import linkedin from "../theme/images/linkedin.png"
import instagram from "../theme/images/instagram.png"
import devto from "../theme/images/devto.png"

function Header() {
  return (
    <header className='header'>
            <ul style={{padding:"0.5em 0", listStyle:"none", display:"inline-flex", listStyleType:"none",  }}>

              <Link className='link' to="/" style={{marginRight:"3em"}}>Leo Pivatto</Link>
              <Link className='link'to="/projects" style={{marginRight:"3em"}} href="/projects">Projects</Link>
              <Link className='link'to="/cv" style={{marginRight:"3em"}} href="/cv">CV</Link>
              <Link className='link'to="/blog" style={{marginRight:"3em"}} href="/blog">Blog</Link>
              
            </ul>

          </header> 
  );
}

function Footer() {
  return (
    <footer>
          <section className='footer'>
          

          <p>Reach me out </p>
            <div className="icons">
              <a href='https://github.com/LeoPivatto'><img alt='github' src={github} className="github"/></a>
              <a href='https://www.linkedin.com/in/leopivatto/'><img alt='linkedin' src={linkedin} className="linkedin"/></a>
              <a href='https://www.instagram.com/leo_pivatto/'><img alt='instagram' src={instagram} className="instagram"/></a>
              <a href='https://dev.to/leopivatto'><img src={devto} alt='devto' className="devto"/></a>
            </div>
            
          </section>
        </footer>
  )}

export { Header, Footer };
