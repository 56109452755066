
import Pagetemplate from "../Pagetemplate"
import img from "../theme/images/image.jpeg"
import bibleapp from "../theme/images/bibleapp.png"


function Home() {

  return (
    <div className="Home">
          
      <Pagetemplate content={
        <main>
        <section>
          <h1 style={{color:"#3BF686", fontSize:"4rem",}}>Hi Im Leo Pivatto</h1>
          <p>

            I'm Leo, a passionate programmer dedicated to crafting elegant solutions.
            I specialize in Fullstack development. I'm proficient in Javascript, Nodejs, React, Mongodb, Git.
            I'm deeply passionate about Web Development and programming in general, and I'm always eager to learn and stay updated with the latest advancements.



            This portfolio gathers a collection of my side projects and articles.
          </p>
        </section>



        
        

        <section >
          <div className="description">
          <h2 style={{fontSize:"2rem"}}>Latest Projects</h2>
        </div>

        <div className="items">
        <div className="item">
          <a href="https://github.com/LeoPivatto/bible-app">
          <img src={bibleapp} alt="img-code"/>
          <h3>Bible with summary</h3>
          </a>
          <p>Online Bible with summary of each chapter, made with React </p>
        </div>

        <div className="item">
        <a href="https://github.com/LeoPivatto/contactManager">
          <img src={img} alt="img-code"/>
          <h3>Contact Management app</h3>
          </a>
          <p>Rest contact management API made with express and mongodb</p>
        </div>

      
        </div>

        </section>
        </main>
   }/>




        
        
    </div>
  );
}

export default Home;
