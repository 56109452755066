import Pagetemplate from "../Pagetemplate"
import img from "../theme/images/image.jpeg"
import bibleapp from "../theme/images/bibleapp.png"

function Projects() {

  return (
    <div className="Projects">
          
      <Pagetemplate content={
        <main>
        <section >
          <div className="description">
          <h2 style={{fontSize:"3em"}}> Projects</h2>
          <p>Here's a collection of side projects. A blend of coding experiments and personal studies. Most of them don't have any other purpose other than creating something for myself or studying something new that i like .</p>
        </div>

        <div style={{margin:"5rem 0"}} className="items">
        <div className="item">
          <a href="https://github.com/LeoPivatto/bible-app">
          <img src={bibleapp} alt="img-code"/>
          <h3>Bible with summary</h3>
          </a>
          <p>Online Bible with summary of each chapter, made with React </p>
        </div>

        <div className="item">
        <a href="https://github.com/LeoPivatto/contactManager">
          <img src={img} alt="img-code"/>
          <h3>Contact Management app</h3>
          </a>
          <p>Rest contact management API made with express and mongodb</p>
        </div>

        
        </div>

        </section>
        </main>
   }/>




        
        
    </div>
  );
}

export default Projects;
