import Pagetemplate from "../Pagetemplate"


function Blog() {

  return (
    <div className="Blog">
          
      <Pagetemplate content={
        <main>
          <section>
            <div className="description">
        <h1 style={{fontSize:"3rem"}}>Articles that i wrote</h1>
        </div>

{/*         
        <div style={{margin:"5rem 0"}}>
        <h3>CONTROL UIS USING WIRELESS EARBUDS AND ON-FACE INTERACTIONS</h3>
        <p>A few weeks ago, I stumbled upon a very cool research project around using the acoustic signature 
          of gestures to create new interactions with interfaces and decided to implement a prototype version 
          of it in JavaScript.</p>

          </div>

          <div style={{margin:"5rem 0"}}>
        <h3>CONTROL UIS USING WIRELESS EARBUDS AND ON-FACE INTERACTIONS</h3>
        <p>A few weeks ago, I stumbled upon a very cool research project around using the acoustic signature 
          of gestures to create new interactions with interfaces and decided to implement a prototype version 
          of it in JavaScript.</p>

          </div> */}

          <h3>No articles yet</h3>
          
          
          </section>
        </main>
   }/>




        
        
    </div>
  );
}

export default Blog;
